// src/TokenZones.tsx

import React, { useEffect, useState } from "react";
import { message, Card, Select, Button, Modal, Input } from "antd";
import { getCookie } from "./utils";
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as RechartTooltip,
    Legend,
    ReferenceLine,
} from "recharts";

const { Option } = Select;

interface TokenOption {
    id: number;
    name: string;
    mint: string;
}

interface TokenZoneData {
    id: number;
    token_name: string;
    current_value: number;
    floor: number;
    low: number;
    med_low: number;
    med_high: number;
    high: number;
    updated_date: string;
}

interface BollingerData {
    sym_id: number;
    token_name: string;
    token_value: number;
    updated_date: string;
    bol_4h_sma: number;
    bol_4h_upper: number;
    bol_4h_lower: number;
    bol_1d_sma: number;
    bol_1d_upper: number;
    bol_1d_lower: number;
    bol_1w_sma: number;
    bol_1w_upper: number;
    bol_1w_lower: number;
    bol_20d_sma: number;
    bol_20d_upper: number;
    bol_20d_lower: number;
    bol_1m_sma: number;
    bol_1m_upper: number;
    bol_1m_lower: number;
}

interface RSIData {
    sym_id: number;
    token_name: string;
    token_value: number;
    updated_date: string;
    rsi_5: number;
    rsi_9: number;
    rsi_14: number;
    rsi_21: number;
    rsi_30: number;
}

/**
 * Formats a number for the "Current", "Floor", etc. labels.
 * - If abs(value) >= 0.01, show 2 decimals
 * - If 0.0001 <= abs(value) < 0.01, show 4 decimals
 * - Otherwise, show 6 decimals
 */
function formatNumber(value: number): string {
    const absVal = Math.abs(value);
    if (absVal >= 0.01) {
        return value.toFixed(2);
    } else if (absVal >= 0.0001) {
        return value.toFixed(4);
    } else {
        return value.toFixed(6);
    }
}

/**
 * Custom Y-axis formatter:
 * - If abs(value) >= 1, show as an integer.
 * - If 0.0001 <= abs(value) < 1, show 4 decimals.
 * - Otherwise, show 6 decimals.
 */
function formatYAxisTick(value: number): string {
    const absVal = Math.abs(value);
    if (absVal >= 1) {
        return Math.round(value).toString();
    } else if (absVal >= 0.0001) {
        return value.toFixed(4);
    } else {
        return value.toFixed(6);
    }
}

function renderZonesChart(historyData: TokenZoneData[], tokenName: string) {
    if (!historyData.length) {
        return <p>No history available for {tokenName}.</p>;
    }

    const chartData = historyData.map((d) => ({
        time: d.updated_date,
        current: d.current_value,
        floor: d.floor,
        low: d.low,
        med_low: d.med_low,
        med_high: d.med_high,
        high: d.high,
    }));

    return (
        <div
            style={{
                width: "100%",
                height: 600,
                marginTop: 16,
                backgroundColor: "#222",
                padding: "16px",
                borderRadius: "8px",
            }}
        >
            <ResponsiveContainer>
                <LineChart data={chartData} margin={{ top: 40, right: 40, bottom: 40, left: 20 }}>
                    <CartesianGrid stroke="#444" strokeDasharray="3 3" />
                    <XAxis
                        dataKey="time"
                        stroke="#ccc"
                        tickFormatter={(val: string) => {
                            const d = new Date(val);
                            return d.toLocaleDateString();
                        }}
                    />
                    {/* Domain set to auto-fit data, with custom tick formatting */}
                    <YAxis stroke="#ccc" domain={["dataMin", "dataMax"]} tickFormatter={formatYAxisTick} />
                    <RechartTooltip
                        contentStyle={{ backgroundColor: "#333", borderColor: "#666" }}
                        labelStyle={{ color: "#fff" }}
                        itemStyle={{ color: "#fff" }}
                    />
                    <Legend wrapperStyle={{ color: "#fff" }} />

                    <Line
                        type="monotone"
                        dataKey="current"
                        stroke="#8884d8"
                        strokeWidth={2}
                        dot={false}
                        name="Current"
                    />
                    <Line
                        type="monotone"
                        dataKey="floor"
                        stroke="#999"
                        strokeDasharray="3 3"
                        dot={false}
                        name="Floor"
                    />
                    <Line
                        type="monotone"
                        dataKey="low"
                        stroke="#4fd156"
                        dot={false}
                        name="Low"
                    />
                    <Line
                        type="monotone"
                        dataKey="med_low"
                        stroke="#d1cd4f"
                        dot={false}
                        name="Med-Low"
                    />
                    <Line
                        type="monotone"
                        dataKey="med_high"
                        stroke="#d1974f"
                        dot={false}
                        name="Med-High"
                    />
                    <Line
                        type="monotone"
                        dataKey="high"
                        stroke="#d14f4f"
                        dot={false}
                        name="High"
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

function renderBollingerChart(
    bollingerData: BollingerData[],
    tokenName: string,
    selectedWindow: string,
    onTimeWindowChange: (newWindow: string) => void
) {
    if (!bollingerData.length) {
        return <p style={{ color: "#fff" }}>No Bollinger data available for {tokenName}.</p>;
    }

    // Mapping of drop-down option to the keys in the returned data
    const mapping: Record<string, { sma: string; upper: string; lower: string }> = {
        "4h": { sma: "bol_4h_sma", upper: "bol_4h_upper", lower: "bol_4h_lower" },
        "1d": { sma: "bol_1d_sma", upper: "bol_1d_upper", lower: "bol_1d_lower" },
        "1w": { sma: "bol_1w_sma", upper: "bol_1w_upper", lower: "bol_1w_lower" },
        "20d": { sma: "bol_20d_sma", upper: "bol_20d_upper", lower: "bol_20d_lower" },
        "1m": { sma: "bol_1m_sma", upper: "bol_1m_upper", lower: "bol_1m_lower" },
    };

    const selectedMapping = mapping[selectedWindow];

    const chartData = bollingerData.map((d) => ({
        time: d.updated_date,
        sma: d[selectedMapping.sma as keyof BollingerData],
        upper: d[selectedMapping.upper as keyof BollingerData],
        lower: d[selectedMapping.lower as keyof BollingerData],
        current: d.token_value,
    }));

    return (
        <div
            style={{
                width: "100%",
                height: 600,
                marginTop: 16,
                backgroundColor: "#222",
                padding: "16px",
                borderRadius: "8px",
            }}
        >
            <div style={{ marginBottom: 8, color: "#fff" }}>
                <span style={{ marginRight: 8 }}>Bollinger Band Time Window:</span>
                <Select value={selectedWindow} onChange={onTimeWindowChange} style={{ width: 100 }}>
                    <Option value="4h">4h</Option>
                    <Option value="1d">1d</Option>
                    <Option value="1w">1w</Option>
                    <Option value="20d">20d</Option>
                    <Option value="1m">1m</Option>
                </Select>
            </div>
            <ResponsiveContainer>
                <LineChart data={chartData} margin={{ top: 40, right: 40, bottom: 40, left: 20 }}>
                    <CartesianGrid stroke="#444" strokeDasharray="3 3" />
                    <XAxis
                        dataKey="time"
                        stroke="#ccc"
                        tickFormatter={(val: string) => {
                            const d = new Date(val);
                            return d.toLocaleDateString();
                        }}
                    />
                    {/* Domain set to auto-fit data, with custom tick formatting */}
                    <YAxis stroke="#ccc" domain={["dataMin", "dataMax"]} tickFormatter={formatYAxisTick} />
                    <RechartTooltip
                        contentStyle={{ backgroundColor: "#333", borderColor: "#666" }}
                        labelStyle={{ color: "#fff" }}
                        itemStyle={{ color: "#fff" }}
                    />
                    <Legend wrapperStyle={{ color: "#fff" }} />
                    <Line type="monotone" dataKey="sma" stroke="#8884d8" strokeWidth={2} dot={false} name="SMA" />
                    <Line type="monotone" dataKey="upper" stroke="#d14f4f" strokeWidth={2} dot={false} name="Upper Band" />
                    <Line type="monotone" dataKey="lower" stroke="#4fd156" strokeWidth={2} dot={false} name="Lower Band" />
                    <Line type="monotone" dataKey="current" stroke="#ffcc00" strokeWidth={2} dot={false} name="Current" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

function renderRSIChart(
    rsiData: RSIData[],
    tokenName: string,
    selectedWindow: string,
    onTimeWindowChange: (newWindow: string) => void
) {
    if (!rsiData.length) {
        return <p style={{ color: "#fff" }}>No RSI data available for {tokenName}.</p>;
    }

    // Mapping of RSI time window option to the corresponding field in the API response.
    const mapping: Record<string, string> = {
        "5": "rsi_5",
        "9": "rsi_9",
        "14": "rsi_14",
        "21": "rsi_21",
        "30": "rsi_30",
    };
    const field = mapping[selectedWindow];

    const chartData = rsiData.map((d) => ({
        time: d.updated_date,
        rsi: d[field as keyof RSIData],
    }));

    return (
        <div
            style={{
                width: "100%",
                height: 300,
                marginTop: 16,
                backgroundColor: "#222",
                padding: "16px",
                borderRadius: "8px",
            }}
        >
            <div style={{ marginBottom: 8, color: "#fff" }}>
                <span style={{ marginRight: 8 }}>RSI Time Window:</span>
                <Select value={selectedWindow} onChange={onTimeWindowChange} style={{ width: 100 }}>
                    <Option value="5">5</Option>
                    <Option value="9">9</Option>
                    <Option value="14">14</Option>
                    <Option value="21">21</Option>
                    <Option value="30">30</Option>
                </Select>
            </div>
            <ResponsiveContainer>
                <LineChart data={chartData} margin={{ top: 20, right: 40, bottom: 20, left: 20 }}>
                    <CartesianGrid stroke="#444" strokeDasharray="3 3" />
                    <XAxis
                        dataKey="time"
                        stroke="#ccc"
                        tickFormatter={(val: string) => {
                            const d = new Date(val);
                            return d.toLocaleDateString();
                        }}
                    />
                    {/* RSI is always 0-100, so domain is fixed. We'll still apply the custom tick formatter. */}
                    <YAxis stroke="#ccc" domain={[0, 100]} tickFormatter={formatYAxisTick} />
                    <RechartTooltip
                        contentStyle={{ backgroundColor: "#333", borderColor: "#666" }}
                        labelStyle={{ color: "#fff" }}
                        itemStyle={{ color: "#fff" }}
                    />
                    <Legend wrapperStyle={{ color: "#fff" }} />
                    <ReferenceLine y={70} label="Overvalued" stroke="red" />
                    <ReferenceLine y={30} label="Undervalued" stroke="green" />
                    <Line
                        type="monotone"
                        dataKey="rsi"
                        stroke="#82ca9d"
                        strokeWidth={2}
                        dot={false}
                        name={`RSI (${selectedWindow})`}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

const TokenZones: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [tokens, setTokens] = useState<TokenOption[]>([]);
    const [histories, setHistories] = useState<Record<number, TokenZoneData[]>>({});
    const [timeframe, setTimeframe] = useState<string>("all");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [authCode, setAuthCode] = useState("");

    // Bollinger data state (mapping token id to BollingerData[])
    const [bollingerData, setBollingerData] = useState<Record<number, BollingerData[]>>({});
    // Local drop-down selection for Bollinger chart per token (default: "20d")
    const [bollingerTimeWindows, setBollingerTimeWindows] = useState<Record<number, string>>({});

    // New state for RSI data (mapping token id to RSIData[])
    const [rsiData, setRsiData] = useState<Record<number, RSIData[]>>({});
    // Local drop-down selection for RSI chart per token (default: "14")
    const [rsiTimeWindows, setRsiTimeWindows] = useState<Record<number, string>>({});

    const backendUrl = process.env.REACT_APP_TP_BACK_URL;

    useEffect(() => {
        const jwtToken = getCookie("CF_Authorization");
        if (!jwtToken) {
            console.error("Auth Error: JWT token not found");
            message.error("You must be logged in.");
            setLoading(false);
            return;
        }

        fetch(`${backendUrl}/api/token-lookup`, {
            headers: {
                "Content-Type": "application/json",
                "cf-access-jwt-assertion": jwtToken,
            },
        })
            .then((resp) => {
                if (!resp.ok) throw new Error("Failed to fetch token list");
                return resp.json();
            })
            .then((tokenList: TokenOption[]) => {
                setTokens(tokenList);

                return Promise.all(
                    tokenList.map(async (tok) => {
                        let url = `${backendUrl}/api/token-history/${tok.id}`;
                        if (timeframe === "1d" || timeframe === "1w" || timeframe === "1m") {
                            url = `${backendUrl}/api/token-history/${timeframe}/${tok.id}`;
                        }
                        const res = await fetch(url, {
                            headers: {
                                "Content-Type": "application/json",
                                "cf-access-jwt-assertion": jwtToken,
                            },
                        });
                        if (!res.ok) {
                            console.error(`Error fetching history for token id=${tok.id}`);
                            return { id: tok.id, data: [] };
                        }
                        const data: TokenZoneData[] = await res.json();
                        return { id: tok.id, data };
                    })
                );
            })
            .then((results) => {
                const dict: Record<number, TokenZoneData[]> = {};
                for (const item of results) {
                    dict[item.id] = item.data;
                }
                setHistories(dict);
            })
            .catch((err) => {
                console.error("Error fetching tokens or histories:", err);
                message.error("Could not load tokens or their histories. Please try again.");
            })
            .finally(() => setLoading(false));
    }, [backendUrl, timeframe]);

    // Initialize Bollinger Time Windows for tokens with default value "20d"
    useEffect(() => {
        if (tokens.length > 0) {
            setBollingerTimeWindows((prev) => {
                const newWindows = { ...prev };
                tokens.forEach((token) => {
                    if (!newWindows[token.id]) {
                        newWindows[token.id] = "20d";
                    }
                });
                return newWindows;
            });
        }
    }, [tokens]);

    // Initialize RSI Time Windows for tokens with default value "14"
    useEffect(() => {
        if (tokens.length > 0) {
            setRsiTimeWindows((prev) => {
                const newWindows = { ...prev };
                tokens.forEach((token) => {
                    if (!newWindows[token.id]) {
                        newWindows[token.id] = "14";
                    }
                });
                return newWindows;
            });
        }
    }, [tokens]);

    // Fetch Bollinger data for each token using the global timeframe (API Age)
    useEffect(() => {
        const jwtToken = getCookie("CF_Authorization");
        if (!jwtToken) return;
        tokens.forEach((token) => {
            let url = `${backendUrl}/api/token-bollinger`;
            if (timeframe === "1d" || timeframe === "1w" || timeframe === "1m") {
                url += `/${timeframe}/${token.id}`;
            } else {
                url += `/${token.id}`;
            }
            fetch(url, {
                headers: {
                    "Content-Type": "application/json",
                    "cf-access-jwt-assertion": jwtToken,
                },
            })
                .then((resp) => {
                    if (!resp.ok)
                        throw new Error(`Failed to fetch Bollinger data for token id=${token.id}`);
                    return resp.json();
                })
                .then((data: BollingerData[]) => {
                    setBollingerData((prev) => ({ ...prev, [token.id]: data }));
                })
                .catch((err) => {
                    console.error("Error fetching Bollinger data:", err);
                });
        });
    }, [tokens, timeframe, backendUrl]);

    // Fetch RSI data for each token using the global timeframe (API Age)
    useEffect(() => {
        const jwtToken = getCookie("CF_Authorization");
        if (!jwtToken) return;
        tokens.forEach((token) => {
            let url = `${backendUrl}/api/token-rsi`;
            if (timeframe === "1d" || timeframe === "1w" || timeframe === "1m") {
                url += `/${timeframe}/${token.id}`;
            } else {
                url += `/${token.id}`;
            }
            fetch(url, {
                headers: {
                    "Content-Type": "application/json",
                    "cf-access-jwt-assertion": jwtToken,
                },
            })
                .then((resp) => {
                    if (!resp.ok)
                        throw new Error(`Failed to fetch RSI data for token id=${token.id}`);
                    return resp.json();
                })
                .then((data: RSIData[]) => {
                    setRsiData((prev) => ({ ...prev, [token.id]: data }));
                })
                .catch((err) => {
                    console.error("Error fetching RSI data:", err);
                });
        });
    }, [tokens, timeframe, backendUrl]);

    const handleBollingerTimeWindowChange = (tokenId: number, newWindow: string) => {
        setBollingerTimeWindows((prev) => ({ ...prev, [tokenId]: newWindow }));
    };

    const handleRsiTimeWindowChange = (tokenId: number, newWindow: string) => {
        setRsiTimeWindows((prev) => ({ ...prev, [tokenId]: newWindow }));
    };

    if (loading) {
        return <p>Loading token zones...</p>;
    }

    if (!tokens.length) {
        return <p>No tokens found.</p>;
    }

    const showModal = () => {
        setAuthCode("");
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        setIsModalOpen(false);
        if (!authCode.trim()) {
            message.error("Please enter an auth code.");
            return;
        }
        try {
            const jwtToken = getCookie("CF_Authorization");
            if (!jwtToken) {
                message.error("You must be logged in.");
                return;
            }
            const res = await fetch(`${backendUrl}/api/resync-quadrants/${authCode}`, {
                headers: {
                    "Content-Type": "application/json",
                    "cf-access-jwt-assertion": jwtToken,
                },
            });
            if (!res.ok) {
                throw new Error("Resync call failed");
            }
            message.success("Resync triggered successfully.");
        } catch (err) {
            console.error("Resync error:", err);
            message.error("Resync failed. Check console/logs for details.");
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div style={{ padding: 16 }}>
            <h1>Token Zones</h1>

            <div style={{ marginBottom: 16 }}>
                <Select
                    value={timeframe}
                    onChange={(value) => setTimeframe(value)}
                    style={{ width: 200 }}
                >
                    <Option value="all">All Time</Option>
                    <Option value="1d">One Day</Option>
                    <Option value="1w">One Week</Option>
                    <Option value="1m">One Month</Option>
                </Select>
            </div>

            {tokens.map((token) => {
                const zoneData = histories[token.id] || [];
                const latest = zoneData.length ? zoneData[zoneData.length - 1] : null;
                const tokenBollingerData = bollingerData[token.id] || [];
                const selectedBollingerWindow = bollingerTimeWindows[token.id] || "20d";
                const tokenRsiData = rsiData[token.id] || [];
                const selectedRsiWindow = rsiTimeWindows[token.id] || "14";

                return (
                    <Card
                        key={token.id}
                        style={{ marginBottom: 16 }}
                        title={`Token: ${token.name} (ID: ${token.id})`}
                    >
                        {latest ? (
                            <div style={{ marginBottom: 8 }}>
                                <p>
                                    <strong>Current:</strong> {formatNumber(latest.current_value)}
                                </p>
                                <p>
                                    <strong>Floor:</strong> {formatNumber(latest.floor)}
                                </p>
                                <p>
                                    <strong>Low:</strong> {formatNumber(latest.low)}
                                </p>
                                <p>
                                    <strong>Med-Low:</strong> {formatNumber(latest.med_low)}
                                </p>
                                <p>
                                    <strong>Med-High:</strong> {formatNumber(latest.med_high)}
                                </p>
                                <p>
                                    <strong>High:</strong> {formatNumber(latest.high)}
                                </p>
                                <p>
                                    <strong>Updated:</strong>{" "}
                                    {new Date(latest.updated_date).toLocaleString()}
                                </p>
                            </div>
                        ) : (
                            <p>No zone data available for this timeframe.</p>
                        )}
                        {renderZonesChart(zoneData, token.name)}
                        {renderBollingerChart(
                            tokenBollingerData,
                            token.name,
                            selectedBollingerWindow,
                            (newWindow: string) => handleBollingerTimeWindowChange(token.id, newWindow)
                        )}
                        {renderRSIChart(
                            tokenRsiData,
                            token.name,
                            selectedRsiWindow,
                            (newWindow: string) => handleRsiTimeWindowChange(token.id, newWindow)
                        )}
                    </Card>
                );
            })}

            <div style={{ textAlign: "center", marginTop: 24 }}>
                <Button type="primary" onClick={showModal}>
                    Resync Quadrants
                </Button>
            </div>

            <Modal
                title="Enter Auth Code"
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Input
                    placeholder="Auth code..."
                    value={authCode}
                    onChange={(e) => setAuthCode(e.target.value)}
                />
            </Modal>
        </div>
    );
};

export default TokenZones;
